var initialised = false;


/**
 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
 *
 * initEvents
 *
 * @param {boolean} reinit variable de controle si 'initEvents' est appelé pour une réinitialisation ou une première instance
 *
 * @description Initialisation de tout les évènement du système d'indexation, appelé pendand la première
 * Chargement complet d'une page côté angular
 *
 */
function initEvents(reinit = false) {

    initialised = true;


    let elementSelectors = {
        //main containers
        previsualisation: ".prev-container",
        formConaitainer: ".form-container",

        //scrollers
        generalScroller: ".sclr",
        focussableScroller: ".focuss-scroller",
        clickableScroller: ".scroller-clickable",
        radioScroller: ".scroller-radio",

        focusedAction: ".focused-action",
        focusedPrev: ".focused-prev",

        prevIndex: ".ref",
        formIputContainer: "#form-inputs-cnt",
        cursorLine: ".cursor-line",
        grabCursor: ".grab",

        offsetExtra: ".offset",
        // For angular
        initiator: "#initiator"
    };

    let dataTypes = {
        index: "indexed"
    }

    // variables de controle système aimant indexé
    var activated_form_element = null;
    var is_scroll_detection_active = true;
    var start_sroll_magnet_listener = null;

    // temps de lancement de scroll après detection
    var start_sroll_magnet_timeout = 0;

    // Variable de contre de système d'imant après scroll
    var ajust_position_magnet_listener = null;
    var ajust_postion_magnet_timeout = 1300;

    var activation_timeout_scroll_listener = null;
    var reactivation_scroll_listener_timeout = 3000;

    var activation_timout_sync = null;
    var sync_postion_activation_timout = 2000;

    var sync_starter_timeout_listener = null;
    var sync_starter_timeout = 100;


    var activate_sync_position = false;

    var margin_listener_detection = 5;

    // control de status du curseur
    let draggin_cusrsor_listener = null;
    let dragging_cursor_timeout = 1500;
    let draggin_cursor = false;

    let disactivation_after_blur_listener = null;
    let desactivation_time_after_input_blur = 5000;

    let is_scrolling = false;


    $(elementSelectors.previsualisation).css("padding-bottom", $(elementSelectors.formConaitainer).height());


    // Dragable cursor settings
    $(elementSelectors.cursorLine + " " + elementSelectors.grabCursor).draggable({
        axis: "y",
        containment: "parent",
        start: (event, ui) => {

            clearTimeout(draggin_cusrsor_listener);
            clearTimeout(disactivation_after_blur_listener);

            draggin_cursor = true;

        },
        stop: (event, ui) => {

            scrollStart(activated_form_element == null ? $(elementSelectors.generalScroller).first() : activated_form_element, {
                scrollForm: true,
                changeFormFocus: (activated_form_element == null),
                changePrevFocus: (activated_form_element == null),
                callBack: () => {

                    if (activated_form_element != null) {
                        activated_form_element.trigger("focus");
                    }

                }
            });

            let off_ = 0;

            $(elementSelectors.offsetExtra).each(function(i) {

                off_ += $(this).outerHeight(true);

            });

            $(document).scrollTop(off_ - 100);

            draggin_cusrsor_listener = setTimeout(() => {
                draggin_cursor = false;
            }, dragging_cursor_timeout);



        }
    });


    // Evenement scroll de la formulaire
    $(elementSelectors.formConaitainer).on("scroll", function() {

        // Not focused magnet
        if (is_scroll_detection_active) {
            //console.log("Lasa ny Détéction");

            let grab_pos = Math.round($(elementSelectors.cursorLine + " " + elementSelectors.grabCursor).position().top);

            $(elementSelectors.generalScroller).each(function() {


                let off_top = Math.round($(this).offset().top);

                // vérification si un décleucheur d'indexation est détécté
                if ((off_top + margin_listener_detection) >= grab_pos && (off_top - margin_listener_detection) <= grab_pos) {


                    if (activated_form_element == null) {


                        if (start_sroll_magnet_listener != null) {
                            clearTimeout(start_sroll_magnet_listener);
                        }

                        // start_sroll_magnet_listener = setTimeout(() => {

                        // 	scrollStart($(this), {
                        // 		scrollForm: false, prevAnimationDuration: 0, changeFormFocus: true, callBack: () => {

                        // 			//align form to cursor automatically
                        // 			// ajust_position_magnet_listener = setTimeout(() => {

                        // 			//   scrollStart(activated_form_element, { scrollForm: true, prevAnimationDuration: 0, changeFormFocus: false });

                        // 			// }, ajust_postion_magnet_timeout);

                        // 		}
                        // 	});

                        // }, start_sroll_magnet_timeout);

                        scrollStart($(this), {
                            scrollForm: false,
                            prevAnimationDuration: 0,
                            changeFormFocus: true,
                            callBack: () => {}
                        });

                        // activer le scroll détécté
                        // activated_form_element = $(this);

                    } else if (activated_form_element.data(dataTypes.index) != $(this).data(dataTypes.index)) {

                        //console.log("Activation ", $(this).data(dataTypes.index));

                        // if (start_sroll_magnet_listener != null) {
                        // 	clearTimeout(start_sroll_magnet_listener);
                        // }

                        // start_sroll_magnet_listener = setTimeout(() => {

                        // 	scrollStart($(this), {
                        // 		focusParent: $(this).hasClass(elementSelectors.radioScroller.replace('.', '')),
                        // 		scrollForm: false, prevAnimationDuration: 0, changeFormFocus: true, callBack: () => {

                        // 			//align form to cursor automatically
                        // 			// ajust_position_magnet_listener = setTimeout(() => {

                        // 			//   scrollStart(activated_form_element, { scrollForm: true, prevAnimationDuration: 0, changeFormFocus: false });

                        // 			// }, ajust_postion_magnet_timeout);

                        // 		}
                        // 	});

                        // }, start_sroll_magnet_timeout);

                        scrollStart($(this), {
                            focusParent: $(this).hasClass(elementSelectors.radioScroller.replace('.', '')),
                            scrollForm: false,
                            prevAnimationDuration: 0,
                            changeFormFocus: true,
                            callBack: () => {

                                //align form to cursor automatically
                                // ajust_position_magnet_listener = setTimeout(() => {

                                //   scrollStart(activated_form_element, { scrollForm: true, prevAnimationDuration: 0, changeFormFocus: false });

                                // }, ajust_postion_magnet_timeout);

                            }
                        });

                        activated_form_element = $(this);

                    }

                }

            });

        }

        // focused mangnet indexed
        if (activate_sync_position && activated_form_element != null) {


            clearTimeout(sync_starter_timeout_listener);


            sync_starter_timeout_listener = setTimeout(() => {

                scrollStart($(elementSelectors.focusedAction), { scrollForm: false, prevAnimationDuration: 0, formAnimationDuration: 0, changePrevFocus: false, changeFormFocus: false, referedToTrigger: true });

            }, sync_starter_timeout);

        }

    });


    // Evènement blur de déclencheur de scroll dans la formulaire
    $(elementSelectors.generalScroller).on("blur", function() {

        if (!draggin_cursor) {

            $(elementSelectors.focusedPrev).removeClass(elementSelectors.focusedPrev.replace('.', ''));
            $(elementSelectors.focusedAction).removeClass(elementSelectors.focusedAction.replace('.', ''));

        }

        activate_sync_position = false;

        clearTimeout(activation_timout_sync);


        activation_timeout_scroll_listener = setTimeout(() => {
            is_scroll_detection_active = true;
        }, reactivation_scroll_listener_timeout);


        //  Réinitialisation de l'élément active si aucun action n'est pas fait
        disactivation_after_blur_listener = setTimeout(() => {
            activated_form_element = null;
        }, desactivation_time_after_input_blur);


    });


    // Evènement de déclenchement de scroll dans la formulaire
    $(elementSelectors.generalScroller).on('focus click', function(e) {

        console.log("Scroller clicked...", $(this).data("indexed"));

        clearTimeout(disactivation_after_blur_listener);
        clearTimeout(activation_timeout_scroll_listener);

        activated_form_element = $(this);

        // Désactivation de comte à rebour de desactivation d'élément
        is_scroll_detection_active = false;


        if (!is_scrolling) {

            is_scrolling = true;

            scrollStart($(this), {

                focusParent: $(this).hasClass(elementSelectors.radioScroller.replace('.', '')),
                callBack: () => {

                    is_scrolling = false;

                    is_scroll_detection_active = true;

                }

            });

        }

    });


    // Evènement de déclenchement de scroll dans la prévisualisation
    $(elementSelectors.prevIndex).on('click', function(e) {

        console.log("Prev clicked...", $(this).attr("id"));

        is_scroll_detection_active = false;

        scroller_trigger = $(elementSelectors.generalScroller + "[data-" + dataTypes.index + "='#" + $(this).attr("id") + "']");

        console.log("is checkbox ", scroller_trigger.hasClass("main-shitch-radio"));


        scroller_trigger.trigger('click');
        scroller_trigger.trigger('focus');

        e.stopImmediatePropagation();

        return false;

        // if ( $(this).hasClass("stop-propagation")){

        // 	e.stopImmediatePropagation();

        // 	return false;

        // }
        // scroller_trigger.trigger('focus');
        // $(elementSelectors.radioScroller + "[data-" + dataTypes.index + "='#" + $(this).attr("id") + "']").parent().addClass(elementSelectors.focusedAction.replace('.', ''));

    });


    // Default state
    let get_offset = () => {

        let off_ = 0;

        $(elementSelectors.offsetExtra).each(function(i) {

            off_ += $(this).outerHeight(true);

        });

        return off_;

    };

    let off_ = get_offset();

    $(elementSelectors.offsetExtra).each(function(i) {

        off_ += $(this).outerHeight(true);

    });

    $(elementSelectors.previsualisation).on("click", function() {
        $(document).scrollTop(get_offset() - 115);
    });

    $(elementSelectors.formConaitainer).on("click", function() {
        $(document).scrollTop(get_offset() - 115);
    });

    if (!reinit) {

        $(document).scrollTop(off_ - 115);
        // $(elementSelectors.generalScroller).first().trigger("focus");
        $(elementSelectors.previsualisation).scrollTop(0);
        $(elementSelectors.formConaitainer).scrollTop($(elementSelectors.formConaitainer).outerHeight());

    }

}


/**
 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
 *
 * reinitEvents
 *
 * @description Réinitialisation de tout les évènement du système d'indexation, appelé pendant des
 * évèment qui met à jour le DOM par angular
 *
 */
function reinitEvents() {

    // console.log("Reinit..");

    let elementSelectors = {
        //main containers
        previsualisation: ".prev-container",
        formConaitainer: ".form-container",

        //scrollers
        generalScroller: ".sclr",
        focussableScroller: ".focuss-scroller",
        clickableScroller: ".scroller-clickable",
        radioScroller: ".scroller-radio",

        focusedAction: ".focused-action",
        focusedPrev: ".focused-prev",

        prevIndex: ".ref",
        formIputContainer: "#form-inputs-cnt",
        cursorLine: ".cursor-line",
        grabCursor: ".grab",

        offsetExtra: ".offset",
        // For angular
        initiator: "#initiator"
    };

    $(elementSelectors.formConaitainer).off("scroll");
    $(elementSelectors.generalScroller).off("blur");
    $(elementSelectors.generalScroller).off('click');
    $(elementSelectors.generalScroller).off('focus');
    $(elementSelectors.prevIndex).off('click');
    $(elementSelectors.previsualisation).off("click");
    $(elementSelectors.formConaitainer).off("click");

    initEvents(true);

}


/**
 * @author R. Jean Christian <jrazanamihoatra@ingenosya.mg>
 *
 * @param {DomElemet} scollerElement Element représentant le déclencheur de l'indexation
 * @param {Object} optionsIn  {
 * }
 *
 * @description Fonction coeur de déclenchement de scroll à partir d'un trigger
 *
 */
function scrollStart(scollerElement, optionsIn) {

    let options = {
        formContainer: $('.form-container'),
        prevContainer: $('.prev-container'),
        callBack: () => {},
        focusParent: false,
        withTopExtraOffset: true,
        scrollForm: true,
        changeFormFocus: true,
        changePrevFocus: true,
        referedToTrigger: false,
        activateElemenAftenished: true,
        formAnimationDuration: 500,
        prevAnimationDuration: 550,
        selectors: {
            focusedInput: "focused-action",
            focusedPrev: "focused-prev",
            topExtraOffset: "offset",
            cursorLine: "cursor-line",
            grabCursor: "grab"
        },
        dataTypes: {
            prevIndex: "indexed"
        }
    };


    try {

        // Init options values
        if (optionsIn != undefined) {

            options = {
                ...options,
                ...optionsIn,
                selectors: {
                    ...options.selectors,
                    ...(optionsIn.selectors ? optionsIn.selectors : {})
                },
                dataTypes: {
                    ...options.dataTypes,
                    ...(optionsIn.dataTypes ? optionsIn.dataTypes : {})
                }
            };

        }


        if (options.changeFormFocus) {

            $("." + options.selectors.focusedInput).removeClass(options.selectors.focusedInput);

        }

        if (options.changePrevFocus) {

            $("." + options.selectors.focusedPrev).removeClass(options.selectors.focusedPrev);

        }

        // calculate page offset
        let offset_general = 0;

        $("." + options.selectors.topExtraOffset).each(function(i) {

            // offset_general += $(this).innerHeight();
            offset_general += $(this).outerHeight(true);

        });

        var input_position = scollerElement.position();

        let cursor_position = $("." + options.selectors.cursorLine + " ." + options.selectors.grabCursor).first().position();
        // let input_offset = scollerElement.offset();

        // let input_offset
        let right_offset = $(scollerElement.data(options.dataTypes.prevIndex)).offset();


        if (options.scrollForm && !options.referedToTrigger) {

            options.formContainer.scrollTo(scollerElement, options.formAnimationDuration, {
                offset: {
                    top: -cursor_position.top + (options.withTopExtraOffset ? offset_general : 0)
                }
            });

        }


        options.prevContainer.scrollTo($(scollerElement.data(options.dataTypes.prevIndex)), options.prevAnimationDuration, {

            onAfter: (el) => {

                if (options.changePrevFocus) {
                    $(scollerElement.data(options.dataTypes.prevIndex)).addClass(options.selectors.focusedPrev);
                }

                if (options.changeFormFocus) {

                    if (options.focusParent) {

                        scollerElement.parent().parent().addClass(options.selectors.focusedInput);
                        scollerElement.parent().addClass(options.selectors.focusedInput);

                    } else {

                        scollerElement.addClass(options.selectors.focusedInput);

                    }

                }

                is_scrolling = false;
                is_scroll_detection_active = true;

                options.callBack();

            },
            offset: {
                top: -(options.referedToTrigger ? input_position.top : cursor_position.top) + (options.withTopExtraOffset ? offset_general : 0)
            }

        });

    } catch (error) {

        alert("DEVELOPMENT MESSAGE: ANOMALIE D'INDEXATION, INDEX PREVISUALISATOIN=" + scollerElement.data(options.dataTypes.prevIndex));

        console.warn("DEVELOPMENT MESSAGE: ANOMALIE D'INDEXATION, INDEX PREVISUALISATOIN=" + scollerElement.data(options.dataTypes.prevIndex));

    }

}


function scrollToAnchoInPrev(anchor) {

    if (anchor == '#TOP_') {

        $(".prev-container").scrollTo(0, 0);

    }

    $(".prev-container").trigger("click");

    $(".prev-container").scrollTo($(anchor), 500, {
        offset: {
            top: -30
        }
    });

}


function isInputDisbled(controllName) {

    let selector = "";

    selector = ".sclr[formcontrolname=" + controllName + "]";


    let res = false;

    console.log("controlName from element, ", $(selector).attr("formcontrolname"));

    if ($(selector).attr("formcontrolname") == undefined || $(selector) == null) {

        return true;

    }

    if ($(selector)) {

        console.log("Tab index, ", $(selector).attr("tabindex"));

        if ($(selector).attr("tabindex") == "-1") {

            res = true;

        } else {

            res = false;

        }

        return res;

    } else {

        return false;

    }
}


function accordion() {
    $('.btn').on('click', function() {
        $(this).siblings('input').trigger('click');
    });
    $(document).ready(function() {
        $(document).click(function() {
            $(".dropdown-menu.sous-menu.show").removeClass("show");
        });
        $('.dropdown-item.sous-sm').click(function() {
            $(".dropdown-menu.sous-menu.show").removeClass("show");
        });
        $('.dropdown-sous-menu a.dropdown-toggle').on('click', function(e) {
            if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass('show');
            return false;
        });
    });
}